import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa'; // Import the delete icon
import './JsonEditor.css';

const JsonEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [jsonData, setJsonData] = useState({});
  const [jsonText, setJsonText] = useState('');
  const [name, setName] = useState('');
  const [viewMode, setViewMode] = useState('form');
  const API_PASSWORD = 'SecuredServer$$45';

  // Fetch JSON data on component mount
  useEffect(() => {
    const fetchJsonData = async () => {
      try {
        const res = await axios.get(`https://json-backend.vercel.app/json/${id}`, {
          headers: {
            'x-api-password': API_PASSWORD,
          },
        });
        setJsonData(res.data.data);
        setJsonText(JSON.stringify(res.data.data, null, 2));
        setName(res.data.name);
      } catch (error) {
        console.error("Error fetching JSON data:", error);
        alert("Access denied or error fetching data.");
      }
    };
    fetchJsonData();
  }, [id]);

  // Save changes back to the server
  const handleSave = async () => {
    try {
      await axios.put(
        `https://json-backend.vercel.app/json/${id}`,
        { name, data: jsonData },
        {
          headers: {
            'x-api-password': API_PASSWORD,
          },
        }
      );
      alert("JSON updated successfully!");
    } catch (error) {
      console.error("Error saving JSON data:", error);
      alert("Save failed.");
    }
  };

  // Detect Ctrl+S to trigger save without reload
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === 's') {
        e.preventDefault();
        handleSave();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [jsonData, name]);

  const handleNameChange = (e) => setName(e.target.value);

  const handleJsonChange = (e) => {
    const text = e.target.value;
    setJsonText(text);

    try {
      const parsedData = JSON.parse(text);
      setJsonData(parsedData);
    } catch (error) {
      console.error("Invalid JSON format");
    }
  };

  const handleFieldChange = (path, value) => {
    const updateData = (obj, pathArr) => {
      if (pathArr.length === 1) {
        obj[pathArr[0]] = value;
      } else {
        updateData(obj[pathArr[0]], pathArr.slice(1));
      }
    };

    setJsonData((prevData) => {
      const newData = { ...prevData };
      updateData(newData, path);
      setJsonText(JSON.stringify(newData, null, 2));
      return newData;
    });
  };

  const handleAddField = (path, type) => {
    const addField = (obj, pathArr) => {
      if (pathArr.length === 0) {
        if (type === 'array') {
          if (Array.isArray(obj)) {
            obj.push({});
          } else {
            console.error("Target is not an array");
          }
        } else if (type === 'object') {
          let newFieldName;
          let index = 1;
          do {
            newFieldName = `newField${index++}`;
          } while (obj.hasOwnProperty(newFieldName));
          obj[newFieldName] = '';
        }
      } else {
        const key = pathArr[0];
        if (!(key in obj)) {
          obj[key] = isNaN(pathArr[1]) ? {} : [];
        }
        addField(obj[key], pathArr.slice(1));
      }
    };
  
    setJsonData((prevData) => {
      const newData = { ...prevData };
      addField(newData, path);
      setJsonText(JSON.stringify(newData, null, 2));
      return newData;
    });
  };

  const handleDeleteField = (path) => {
    const updateData = (obj, pathArr) => {
      if (pathArr.length === 1) {
        if (Array.isArray(obj)) obj.splice(pathArr[0], 1);
        else delete obj[pathArr[0]];
      } else {
        updateData(obj[pathArr[0]], pathArr.slice(1));
      }
    };

    setJsonData((prevData) => {
      const newData = { ...prevData };
      updateData(newData, path);
      setJsonText(JSON.stringify(newData, null, 2));
      return newData;
    });
  };

  const handleFieldNameChange = (path, newName) => {
    const updateData = (obj, pathArr) => {
      if (pathArr.length === 1) {
        const oldKey = pathArr[0];
        if (obj.hasOwnProperty(oldKey)) {
          const value = obj[oldKey];
          delete obj[oldKey]; // Delete the old key
          obj[newName] = value; // Assign value to the new key
        }
      } else {
        updateData(obj[pathArr[0]], pathArr.slice(1)); // Traverse down the path
      }
    };
  
    // Perform deep clone of jsonData to avoid reference issues with nested objects
    const deepClone = (obj) => JSON.parse(JSON.stringify(obj));
  
    setJsonData((prevData) => {
      const newData = deepClone(prevData); // Deep clone to avoid mutations in original object
      updateData(newData, path);
      setJsonText(JSON.stringify(newData, null, 2)); // Sync jsonText with updated jsonData
      return newData;
    });
  };
  

  const renderFormFields = (data, path = []) => {
    if (typeof data === 'object' && !Array.isArray(data)) {
      return (
        <div className="field-group">
          {Object.keys(data).map((key) => (
            <div key={key} className="field">
              <input
                type="text"
                value={key}
                onChange={(e) => handleFieldNameChange([...path, key], e.target.value)}
                className="input-field-name"
              />
              {renderFormFields(data[key], [...path, key])}
              <FaTrash onClick={() => handleDeleteField([...path, key])} className="delete-icon" />
            </div>
          ))}
          <button onClick={() => handleAddField(path, 'object')} className="add-button">Add Field</button>
        </div>
      );
    } else if (Array.isArray(data)) {
      return (
        <div className="array-group">
          {data.map((item, index) => (
            <div key={index} className="array-item">
              <label className="array-label">Array Item {index + 1}</label>
              {renderFormFields(item, [...path, index])}
              <FaTrash onClick={() => handleDeleteField([...path, index])} className="delete-icon" />
            </div>
          ))}
          <button onClick={() => handleAddField(path, 'array')} className="add-button">Add Array Item</button>
        </div>
      );
    } else {
      return (
        <input
          type="text"
          value={data}
          onChange={(e) => handleFieldChange(path, e.target.value)}
          className="input-field"
        />
      );
    }
  };

  return (
    <div className="json-editor">
      <h2>Edit JSON Data</h2>

      <input
        type="text"
        value={name}
        onChange={handleNameChange}
        placeholder="Enter JSON file name"
        className="name-input"
      />

      <button onClick={() => setViewMode(viewMode === 'form' ? 'json' : 'form')} className="toggle-button">
        Switch to {viewMode === 'form' ? 'JSON View' : 'Form View'}
      </button>

      <div className="view-container">
        {viewMode === 'form' ? (
          <div className="form-fields">
            {renderFormFields(jsonData)}
          </div>
        ) : (
          <textarea
            value={jsonText}
            onChange={handleJsonChange}
            rows="15"
            style={{ width: '100%', fontSize: '14px' }}
          />
        )}
      </div>

      <button onClick={handleSave} className="save-button">Save Changes</button>
    </div>
  );
};

export default JsonEditor;
